import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom'
import "./Nav.css";
import { Link } from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    CssBaseline,
    InputBase,
    Menu,
    MenuItem,
    IconButton
  } from "@mui/material";
  import SearchIcon from '@mui/icons-material/Search';

// Pictures
import logo from "../../img/GetUpp_logo.jpg";
import avatar from "../../img/user-profile.png";
import en from "../../img/flags/en.png";
import da from "../../img/flags/da.png";
import CheckIcon from '@mui/icons-material/Check';

import {useTranslation} from "react-i18next";
import LanguageOutlined from '@mui/icons-material/Language';

function Nav() {
    const {t, i18n} = useTranslation();
    const [anchorel, setAnchorel] = React.useState(null);
    const [anchore2, setAnchore2] = React.useState(null);
    const [show, handleShow] = useState(false);
    const isMenuOpen = Boolean(anchorel);
    const isProfileMenuOpen = Boolean(anchore2);
    const location = useLocation();
    // const history = useHistory();

    //  Fade effekt navbar
    const transitionNavBar = () => {
        if (window.scrollY > 100) {
            handleShow(true);
        } else {
            handleShow(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", transitionNavBar);
        return () => window.removeEventListener("scroll", transitionNavBar);
    }, []);

    // Find skærmstørrelse
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {

        const changeWidth = () => {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', changeWidth)

        return () => {
            window.removeEventListener('resize', changeWidth)
        }
    }, []);

    const handleMenuClose = () => {
        setAnchorel(null);
    };
    const handleLanguageMenuOpen = (event) => {
        setAnchorel(event.currentTarget);
    };
    const handleProfileMenuClose = () => {
        setAnchore2(null);
    };
    const handleProfileMenuOpen = (event) => {
        setAnchore2(event.currentTarget);
    };
    const handleProfileMenuChange = (value) => {
        setAnchore2(null);
    };
    const handleLanguageChange = (value) => {
        i18n.changeLanguage(value);
        setAnchorel(null);
    };

    return (    
        <AppBar position="sticky" className="navbar">
            <CssBaseline />
            <Toolbar>
                <Link to='/' className='align-items-center'>
                    <img className="navLogo" src={logo} alt=""/>
                </Link>
                <div className="navList">
                    <Link to="/all-videos" className={`navItems ${location.pathname == '/all-videos' ? 'active' : ''}`}>{t('All Videos')}</Link>
                    <Link to="/favorites" className={`navItems ${location.pathname == '/favorites' ? 'active' : ''}`}>{t('Favorites')}</Link>
                    <Link to="/skema" className={`navItems ${location.pathname == '/skema' ? 'active' : ''}`}>{t('Schedule')}</Link>
                    {/* <h1>{t('Welcome to React')}</h1> */}
                </div>
                <div className="searchBlock">
                    <InputBase
                    placeholder="Search…"
                    classes={{
                        root: "inputRoot",
                        input: "inputInput",
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                    />
                    <div className="searchIcon">
                        <SearchIcon />
                    </div>
                </div>
                <IconButton
                className='language-icon'
                edge="end"
                aria-label="language selected by current user"
                aria-controls="change-language-menu"
                aria-haspopup="true"
                onClick={handleLanguageMenuOpen}
                color="inherit"
                >
                    <LanguageOutlined />
                </IconButton>
                <Menu
                anchorel={anchorel}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id="change-language-menu"
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={handleMenuClose}
                style={{marginTop: '2.2em'}}
                >
                    <MenuItem onClick={() => handleLanguageChange('en')} value="en"><img className='flag-icon' src={en}/> English {i18n.language == 'en' && <CheckIcon className='selected-language'/>}</MenuItem>
                    <MenuItem onClick={() => handleLanguageChange('da')} value="da"><img className='flag-icon' src={da}/> Danish {i18n.language == 'da' && <CheckIcon className='selected-language'/>}</MenuItem>
                </Menu>
                <IconButton
                className='navAvatar'
                edge="end"
                aria-label="profile selected by current user"
                aria-controls="change-profile-menu"
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
                >
                    <img className="navAvatar" src={avatar} alt="" />
                </IconButton>
                <Menu
                anchore2={anchore2}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id="change-profile-menu"
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isProfileMenuOpen}
                onClose={handleProfileMenuClose}
                style={{marginTop: '2.2em'}}
                >
                    <MenuItem  onClick={() => handleProfileMenuChange()}><Link className='profile-links' to='/profile'>My Profile</Link></MenuItem>
                    <MenuItem  onClick={() => handleProfileMenuChange()}><Link className='profile-links' to='/upload'>Upload Video</Link></MenuItem>
                </Menu>
                {/* <Link to='/profile'>
                    <img className="navAvatar" src={avatar} alt="" />
                </Link> */}
            </Toolbar>
        </AppBar>  
    );
}

export default Nav;


// indsæt følgende i img onClick={() => history.push("/")}