import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// views
import LoginScreen from '../screens/Login/LoginScreen';
import HomeScreen from '../screens/Homescreen/HomeScreen';
import SignUpScreen from '../screens/Signup/SignUpScreen';

function PublicRoute (){
    return (
      <Switch>
        <Route exact path="/homepage" component={HomeScreen} />
        <Route exact path="/login" component={LoginScreen} />
        <Route exact path="/signup" component={SignUpScreen} />
        <Redirect to="/homepage" />
      </Switch>
    );
}

export default PublicRoute;