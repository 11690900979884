import { Button, Container, Grid } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import './Admin-dashboard.css';

function AdminDashboard() {
    const {t, i18n} = useTranslation();
    return (
            <div style={{paddingBottom:'1em'}}>
                <Container maxWidth="md">
                    <div className='user-detail-block'>
                        <p className='users-section-title'>{t('Users')} :</p>
                        <Grid container spacing={3} className='user-detail-row align-items-center'>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                Fie Tang
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                Sldst logget Ind: 20-12-2021
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <Button className="remove-user-button">{t('Remove')}</Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} className='user-detail-row align-items-center'>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                Fie Tang
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                Sldst logget Ind: 20-12-2021
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <Button className="remove-user-button">{t('Remove')}</Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} className='user-detail-row align-items-center'>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                Fie Tang
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                Sldst logget Ind: 20-12-2021
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <Button className="remove-user-button">{t('Remove')}</Button>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <p className='users-section-title'>{t('Create New User')} :</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Link to='/add-user' className='router-link'>
                                {t('Create New User')}
                            </Link>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        );
    
};

export default AdminDashboard;
