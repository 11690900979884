import React from 'react';
import { Input, InputLabel, MenuItem, Select ,TextField , Grid } from '@mui/material';
import {useTranslation} from "react-i18next";
import './Add-user.css';

function AddUser() {
    const {t, i18n} = useTranslation();
    
    const onOptionChange = () => {

    };

        return (
            <div>
                <p className='section-title'>{t('Create New User')} :</p>
                <form className="create-user-form" noValidate autoComplete="off">
                    <div className='align-items-center'>
                        <InputLabel id="url-key-label">{t('Firstname')}</InputLabel>
                        <Input className="create-user-form-input" placeholder="" inputProps={{ 'aria-label': 'firstname' }} />
                    </div>
                    <div className='align-items-center'>
                        <InputLabel id="url-key-label">{t('Lastname')}</InputLabel>
                        <Input className="create-user-form-input" placeholder="" inputProps={{ 'aria-label': 'lastname' }} />
                    </div>
                    <div className='align-items-center'>
                        <InputLabel id="url-key-label">{t('Email')}</InputLabel>
                        <Input className="create-user-form-input" placeholder="" inputProps={{ 'aria-label': 'email' }} />
                    </div><div className='align-items-center'>
                        <InputLabel id="url-key-label">{t('Password')}</InputLabel>
                        <Input className="create-user-form-input" placeholder="" inputProps={{ 'aria-label': 'password' }} />
                    </div>
                    <div className='align-items-center'>
                        <button type="submit" className='create-user-button'>{t('Opret Bruger')}</button>
                    </div>
                </form>
            </div>
        );
    
};

export default AddUser;
