import React,{Suspense} from 'react';
import './Skema.css';
import { Grid, Card, CardContent } from '@mui/material';
import { PlayArrow } from '@mui/icons-material';
import imageFile from '../../img/Aandedraet.jpg';
import en from "../../img/flags/en.png";
import da from "../../img/flags/da.png";
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {useTranslation} from "react-i18next";

function Skema() {
    const {t, i18n} = useTranslation();

    const createCard = () => {
        
        return (
            <Card className="video-card" style={{position:'relative'}}>
                <div className='overlay'></div>
                <button
                    disabled={false}
                    style={{
                    display:'none'
                    }}
                    className="arrow-buttons play-button"
                >
                    <PlayArrow />
                </button>
                {/* <PlayArrow className=""/> */}
                <CardContent className='video-card-content'>
                    <Suspense fallback={<img src={imageFile} alt='Avatar' className='video-slider-media' />}>
                        <img src={imageFile} alt='Avatar' className='video-slider-media' />
                    </Suspense>
                    <p className='card-title'> <img className='flag-icon' src={en}/> Video Title Here</p>
                    <p className='card-detail-text'><PersonIcon className='material-icon'/> Duration</p>
                    <p className='card-detail-text'><AccessTimeIcon className='material-icon'/> Duration</p>
                </CardContent>
            </Card>
        );
    };

    return (
        <div className='skema-page'>
            <Grid container spacing={2} columns={10}>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <p className='column-title text-align-center'>{t('Monday')}</p>
                    <div>{createCard()}</div>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <p className='column-title text-align-center'>{t('Tuesday')}</p>
                    <div>{createCard()}</div>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <p className='column-title text-align-center'>{t('Wednesday')}</p>
                    <div>{createCard()}</div>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <p className='column-title text-align-center'>{t('Thursday')}</p>
                    <div>{createCard()}</div>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <p className='column-title text-align-center'>{t('Friday')}</p>
                    <div>{createCard()}</div>
                </Grid>
                
                {/* <Grid item xs={12} sm={12} md={7} lg={7}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <p>Wednesday</p>
                            <div>{createCard()}</div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <p>Thursday</p>
                            <div>{createCard()}</div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <p>Friday</p>
                            <div>{createCard()}</div>
                        </Grid>
                    </Grid>
                </Grid> */}
            </Grid>
        </div>
    )
}

export default Skema;
