import React, { useEffect } from 'react';
import "./SignUpScreen.css";

// Sider
import Footer from '../../components/Footer/Footer';
import SignUpForm from '../../components/Signup/SignUpForm';
import NavForm from '../../components/Navbar/NavForm';

function SignUpScreen() {
    useEffect(() => {
        document.title = `Bliv medlem`;
      });

    return (
        <div className="signUpScreen">
            <NavForm />
            <SignUpForm />
            <Footer />
        </div>
    )
}

export default SignUpScreen;
