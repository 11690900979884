import { createSlice } from '@reduxjs/toolkit'

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    isLoggedIn: false,
    user: {name:'Bhoomika',role:'Admin'}
  },
  reducers: {
    loginUser: (state) => {
      state.isLoggedIn = true;
    },
    logoutUser: (state) => {
      state.isLoggedIn = false;
    }
  },
})

// Action creators are generated for each case reducer function
export const { loginUser, logoutUser } = loginSlice.actions

export default loginSlice.reducer