import React, { useState, useContext } from 'react';
import { Grid, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import {useTranslation} from "react-i18next";
import "./Dashboard.css";
import VideoThumbnail from '../../img/Aandedraet.jpg';
import InstructorIcon from '../../img/quick-search-icons/Instructor.png';
import HoldIcon from '../../img/quick-search-icons/Hold.png';
import ProgressIcon from '../../img/quick-search-icons/Progress.png';
import ErgonomicsIcon from '../../img/quick-search-icons/Ergonomics.png';
import OtherIcon from '../../img/quick-search-icons/Other.png';
import { Computer, MoreHoriz, Refresh, FitnessCenter, AccountCircle, ExpandMore, ExpandLess , ChevronLeft, ChevronRight,PlayArrow } from '@mui/icons-material';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

const getItems = () =>
  Array(20)
    .fill(0)
    .map((_, ind) => ({ id: `element-${ind}` }));

function Dashboard() {
    const [items, setItems] = useState(getItems);
    const [selected, setSelected] = useState([]);
    const [position, setPosition] = useState(0);
    const [isExpanded, setExpanded] = useState(false);
    const {t, i18n} = useTranslation();

    const isItemSelected = (id) => !!selected.find((el) => el === id);

  const handleClick =
    (id) =>
    ({ getItemById, scrollToItem }) => {
      const itemSelected = isItemSelected(id);

      setSelected((currentSelected) =>
        itemSelected
          ? currentSelected.filter((el) => el !== id)
          : currentSelected.concat(id)
      );
    };

    const createQuickSearchBlock = () =>{
        return (
            <div>
                <Card className="quick-search-card">
                    <CardContent className='quick-search-card-content'>
                        <img className="quick-search-icons" style={{width:'2em'}} src={InstructorIcon} />
                        <div className='quick-search-titles'>{t('Instructor')}</div>
                    </CardContent>
                </Card>
                <Card className="quick-search-card">
                    <CardContent className='quick-search-card-content'>
                        <img className="quick-search-icons" style={{width:'2.3em', margin:'5px 0'}} src={HoldIcon} />
                        <div className='quick-search-titles'>{t('Hold')}</div>
                    </CardContent>
                </Card>
                <Card className="quick-search-card">
                    <CardContent className='quick-search-card-content'>
                        <img className="quick-search-icons" style={{width:'2.3em', margin:'5px 0'}} src={ProgressIcon} />
                        <div className='quick-search-titles'>{t('Progress')}</div>
                    </CardContent>
                </Card>
                <Card className="quick-search-card">
                    <CardContent className='quick-search-card-content'>
                        <img className="quick-search-icons" style={{width:'2em'}} src={ErgonomicsIcon} />
                        <div className='quick-search-titles'>{t('Ergonomics')}</div>
                    </CardContent>
                </Card>
                <Card className="quick-search-card">
                    <CardContent className='quick-search-card-content'>
                        <img className="quick-search-icons" style={{width:'3em', margin:'9px 0 7px'}} src={OtherIcon} />
                        <div className='quick-search-titles'>{t('Other Things')}</div>
                    </CardContent>
                </Card>
            </div>
        );
    };
    const createCalendarBlock = () =>{
        return (
            <div className='calendar-block'>
                <Card className='dark-calendar-card'>
                    <CardContent className='dark-calendar-card-content' style={{maxHeight : !isExpanded ? '25em' : 'fit-content'}}>
                        {items.map((item,index) => {
                            return (
                                    <Grid container spacing={1}  key={'item'+index} style={{position:'relative'}}  className='event-container'>
                                        <img src={VideoThumbnail} className='image-bg'/>
                                        <Grid item xs={12} sm={12} md={12} lg={12} className="starting-time">
                                            <p>starting time</p>
                                            <p>starting time</p>
                                        </Grid>
                                        <Grid item xs={8} sm={8} md={8} lg={8} className='event-detail'>
                                            <p>person name</p>
                                            <p className='font-weight-bold' style={{fontWeight: '800'}}>Category</p>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4} lg={4} style={{paddingTop: '0'}}>
                                            <CardActions style={{float: 'right'}}>
                                                <Button size="small" color="primary" className='signup-btn'>
                                                    {t('Sign Up')}
                                                </Button>
                                            </CardActions>
                                        </Grid>
                                    </Grid>
                            );
                        })}
                    </CardContent>
                    <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="expand-more-btn">
                                <CardActions className="expand-area" style={{justifyContent: 'center'}}>
                                    <Button size="small" color="primary" className='expand-btn'  onClick={() => {setExpanded(isExpanded ? false : true)}}>
                                        {isExpanded ? <ExpandLess/> : <ExpandMore/>}
                                    </Button>
                                </CardActions>
                            </Grid>
                        </Grid>
                </Card>
            </div>
        );
    };
    const createVideoBlock = () =>{
        return (
            <div className='video-tile-block'>
                <Card>
                    <CardActionArea>
                        <img
                        className='media'
                        src={VideoThumbnail}
                        title="Contemplative Reptile"
                        />
                        <CardContent style={{paddingTop: '0'}}>
                            <Grid container spacing={3}>
                                <Grid item xs={8} sm={8} md={8} lg={8}>
                                    <p className='video-title'>Video Title Here</p>
                                    <p className='video-duration'>Duration</p>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                    <CardActions style={{float: 'right'}}>
                                        <Button size="small" color="primary" className='signup-btn'>
                                            {t('Sign Up')}
                                        </Button>
                                    </CardActions>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </div>
            
        );
    };
    function LeftArrow() {
        const { isFirstItemVisible, scrollPrev } =
          useContext(VisibilityContext);
      
        return (
          <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()} alignment="left">
            <ChevronLeft/>
          </Arrow>
        );
    }
      
    function RightArrow() {
        const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);
      
        return (
          <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()} alignment="right">
            <ChevronRight/>
          </Arrow>
        );
    }

    function Arrow({
        children,
        disabled,
        onClick,
        alignment
      }) {
        return (
          <button
            disabled={disabled}
            onClick={onClick}
            style={{
              opacity: disabled ? "0.5" : "1",
            }}
            className={`${alignment == 'left' ? 'arrow-left' : 'arrow-right'} arrow-buttons`}
          >
            {children}
          </button>
        );
      }

      function VideoCard({ onClick, selected, title, itemId }) {
        const visibility = useContext(VisibilityContext);
      
        return (
        //   <div
        //     onClick={() => onClick(visibility)}
        //     style={{
        //       width: '160px',
        //     }}
        //     tabIndex={0}
        //   >
        //     <div className="card">
        //       hi
        //     </div>
        //     <div
        //       style={{
        //         height: '200px',
        //       }}
        //     />
        //   </div>
        <Card className="video-card" style={{position:'relative'}}>
            <div className='overlay'></div>
            <button
                disabled={false}
                style={{
                display:'none'
                }}
                className="arrow-buttons play-button"
            >
                <PlayArrow />
            </button>
            {/* <PlayArrow className=""/> */}
            <CardContent className='video-card-content'>
                <img src={VideoThumbnail} className='video-slider-media'/>
                <p className='video-scroll-menu-text font-weight-bold'>Video Title Here</p>
                <p className='video-scroll-menu-text'>Duration</p>
            </CardContent>
        </Card>
        );
      }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={3} lg={2} className='quick-search-block'>
                    {createQuickSearchBlock()}
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={10}>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={7} lg={7}>
                            {createVideoBlock()}
                        </Grid>
                        <Grid item xs={12} sm={6} md={5} lg={5}>
                            {createCalendarBlock()}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <p className='font-weight-bold scroll-menu-title'>Senest afspillede</p>
                    <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} className="scroll-menu">
                        {items.map((item) => (
                            <VideoCard
                            item={item}
                            itemId={item.id} // NOTE: itemId is required for track items
                            title={item.id}
                            key={item.id}
                            // onClick={handleClick(id)}
                            // selected={isItemSelected(id)}
                            />
                        ))}
                    </ScrollMenu>
                    <p className='font-weight-bold scroll-menu-title'>Favoritter</p>
                    <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} className="scroll-menu">
                        {items.map((item) => (
                            <VideoCard
                            item={item} // NOTE: itemId is required for track items
                            itemId={item.id} // NOTE: itemId is required for track items
                            title={item.id}
                            key={item.id}
                            // onClick={handleClick(id)}
                            // selected={isItemSelected(id)}
                            />
                        ))}
                    </ScrollMenu>
                    <p className='font-weight-bold scroll-menu-title'>Mest populære</p>
                    <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} className="scroll-menu">
                        {items.map((item) => (
                            <VideoCard
                            item={item} // NOTE: itemId is required for track items
                            itemId={item.id} // NOTE: itemId is required for track items
                            title={item.id}
                            key={item.id}
                            // onClick={handleClick(id)}
                            // selected={isItemSelected(id)}
                            />
                        ))}
                    </ScrollMenu>
                </Grid>
            </Grid>
        </div>
    )
}

export default Dashboard;
