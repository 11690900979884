import React, { useEffect, useState } from 'react';
import { Snackbar , Button, FormControl, Input, InputLabel, MenuItem, OutlinedInput, Select ,TextField, Stack } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TimePicker from '@mui/lab/TimePicker';
import {useTranslation} from "react-i18next";
import './Upload.css';
import configData from '../../config/config.json';
const axios = require('axios');

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} {...props} />;
});

function Upload() {
    const {t, i18n} = useTranslation();
    const [Teams, setTeams] = useState([]);
    const [Instructors, setInstructors] = useState([]);
    const [formData, setFormData] = useState({});
    const [date, setDate] = useState(null);
    const [time, setTime] = useState(null);
    const [open, setOpen] = useState(false);
    const [uploadResponse, setUploadResponse] = useState({status:'',successMessage:'',errors:[]});

    const handleClick = () => {
      setOpen(true);
    };
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

    useEffect(() => {
        axios.get(configData.SERVER_URL+'video/teams')
          .then((response) => {
            setTeams(response.data.details);
          })
          .catch((error) => {
            console.log(error);
          });
        axios.get(configData.SERVER_URL+'video/instructors')
          .then((response) => {
            setInstructors(response.data.details);
          })
          .catch((error) => {
            console.log(error);
          });
    },[]);

    const onOptionChange = (event,id) => {
        console.log(event);
        let FD = formData;
        switch (id) {
            case 'video-url':
                FD.videoUrl = event.target.value;
                break;
            case 'instructor-select':
                FD.instructor = event.target.value;
                break;
            case 'team-select':
                FD.team = event.target.value;
                break;
            case 'language-select':
                FD.language = event.target.value;
                break;
            case 'date-picker':
                setDate(event);
                FD.date = event.getTime();
                break;
            case 'time-picker':
                setTime(event);
                FD.startTime = event.getTime();
                break;
            case 'note-input':
                FD.notes = event.target.value;
                break;
            case 'tag-select':
                FD.tag = event.target.value;
                break;
            default:
                break;
        }
        setFormData(FD);
    };

    const uploadVideo = (event) => {
        event.preventDefault();
        if(!formData.videoUrl || !formData.instructor || !formData.team || !formData.date || !formData.startTime){
            setOpen(true);
            setUploadResponse({status:'error',errors: ['FORM_FIELD_DATA_MISSING']});
            return;
        }
        console.log("Upload : ", formData);
        var config = {
            method: 'post',
            url: configData.SERVER_URL+'video/save',
            data: formData
          };
        axios(config)
          .then((response) => {
            console.log(response.data.successMessage);
            setUploadResponse(response.data)
            setFormData({});
            setDate(null);
            setTime(null);
            setOpen(true);
          })
          .catch((error) => {
            console.log(error.response.data.errors[0]);
            setUploadResponse(error.response.data)
            setOpen(true);
          });
    };

        return (
            <div>
                <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={uploadResponse && uploadResponse.status == 'success' ? 'success' : 'error'} sx={{ width: '100%' }}>
                        {t(uploadResponse.status == 'success' ? uploadResponse.successMessage : uploadResponse.errors[0])}
                    </Alert>
                </Snackbar>
                <form className="upload-form" noValidate autoComplete="off" onSubmit={uploadVideo}>
                    <div className='align-items-center'>
                        <InputLabel id="url-key-label" className="required">{t('URL key')}</InputLabel>
                        <Input required className="upload-form-input" placeholder={t('URL key')} value={formData.videoUrl} inputProps={{ 'aria-label': 'description' }} id='video-url' onChange={(event) => onOptionChange(event,'video-url')}/>
                    </div>
                    <div className='align-items-center'>
                        <InputLabel id="instructor-select-label" className="required">{t('Instructor')}</InputLabel>
                        <Select
                        placeholder={t('Instructor')}
                        labelId="instructor-select-label"
                        id="instructor-select"
                        className="select-input"
                        value={formData.instructor}
                        onChange={(event) => onOptionChange(event,'instructor-select')}
                        required
                        >
                            {Instructors.map((element,index) => {
                                return <MenuItem key={index} value={element}>{element}</MenuItem>
                            })}
                        </Select>
                    </div>
                    <div className='align-items-center'>
                        <InputLabel id="team-select-label" className="required">{t('Team')}</InputLabel>
                        <Select
                        labelId="team-select-label"
                        id="team-select"
                        className="select-input"
                        value={formData.team}
                        onChange={(event) => onOptionChange(event,'team-select')}
                        required
                        >
                            {Teams && Teams.map((element,index) => {
                                return <MenuItem key={index} value={element}>{element}</MenuItem>;
                            })}
                            
                            <MenuItem value='Åndedræt'>Åndedræt</MenuItem>
                            <MenuItem value='Yoga'>Yoga</MenuItem>
                        </Select>
                    </div>
                    <div className='align-items-center'>
                        <InputLabel id="language-select-label" className="required">{t('Language')}</InputLabel>
                        <Select
                        labelId="language-select-label"
                        id="language-select"
                        className="select-input"
                        value={formData.language}
                        onChange={(event) => onOptionChange(event,'language-select')}
                        required
                        >
                            <MenuItem value='English'>{t('English')}</MenuItem>
                            <MenuItem value='Danish'>{t('Danish')}</MenuItem>
                        </Select>
                    </div>
                    <div className='align-items-center'>
                        <InputLabel id="date-select-label" className="required">{t('Date')}</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                id="date-picker"
                                onChange={(event) => onOptionChange(event,'date-picker')}
                                value={date}
                                required
                                renderInput={(params) => <TextField className='upload-form-date-picker' {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className='align-items-center'>
                        <InputLabel id="date-select-label" className="required">{t('Start Time')}</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                                id="time-picker"
                                onChange={(event) => onOptionChange(event,'time-picker')}
                                value={time}
                                required
                                renderInput={(params) => <TextField className='upload-form-date-picker' {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className='align-items-center'>
                        <InputLabel id="note-input-label">{t('Notes')}</InputLabel>
                        <TextField
                            id="note-input"
                            labelId="note-input-label"
                            multiline
                            rows={3}
                            value={formData.notes}
                            variant="standard"
                            onChange={(event) => onOptionChange(event,'note-input')}
                            className="notes-input"
                            placeholder={t('Notes')}
                        />
                    </div>
                    <div className='align-items-center'>
                        <InputLabel id="tag-select-label">{t('Tag/Focus')}</InputLabel>
                        <Select
                        labelId="tag-select-label"
                        id="tag-select"
                        className="select-input"
                        value={formData.tag}
                        onChange={(event) => onOptionChange(event,'tag-select')}
                        >
                            <MenuItem value='tag1'>Tag 1</MenuItem>
                            <MenuItem value='tag2'>Tag 2</MenuItem>
                            <MenuItem value='tag3'>Tag 3</MenuItem>
                        </Select>
                    </div>
                    <div className='align-items-center'>
                        <button type="submit" className='upload-button'>Upload</button>
                    </div>
                </form>
            </div>
        );
    
};

export default Upload;
