import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// views
import AdminScreen from '../components/Admin/Admin-dashboard';
import AddUserComponent from '../components/Admin/Add-user';
import { useSelector } from 'react-redux';

function AdminRoutes (){
  const user = useSelector((state) => state.login.user);
  // const isLoggedIn = useSelector((state) => state.login.isLoggedIn);

    return (
      <Switch>
          <Route path="/add-user" component={AddUserComponent} />
          <Route path="/admin-dashboard" component={AdminScreen} />
          <Redirect to="/admin-dashboard" />
      </Switch>
    );
}

export default AdminRoutes;