import React from 'react';
import "./Banner.css";

// Pictures
import background from "../../img/Yoga.jpg";

function Banner() {
    function truncate(string, n) {
        return string?.length > n ? string.substr(0, n - 1) + '...' : string;
    }

    return (
        <header
            className="banner"
            style={{
                backgroundSize: "cover", // Tilpasser billedet til bredden af skærmen
                backgroundImage: `url(${background})`,
                backgroundPosition: "center center",
        }}>
            <div className="bannerContents">
                {/* Indsæt følgende i h1:  {movie?.title || movie?.original_name} */}
                <h1 className="bannerTitle">GetUpp Live</h1> 
                <div className="bannerButtons">
                    <button className="bannerButton">Næste Live Session</button>
                    <button className="bannerButton">Søg/ Search</button>
                </div>
                <h1 className="bannerDescription">
                   {/* indsæt følgende før nedstående komma: movie?.overview */} {truncate('This is a test description', 10)}</h1>
            </div>

            <div className="bannerFadeBottom" />
        </header>
    );
}

export default Banner;
