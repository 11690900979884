import React, { useState } from 'react';
// import axios from 'axios';
import { Link } from 'react-router-dom';
import "./LoginForm.css";

function LoginForm() {
    // const [values, setValues] = useState({
    //     email: '',
    //     checkbox: '',
    //     password: ''
    // });

    function myFunction() {
        console.log("I am starting yay");
        var axios = require('axios');
        var qs = require('qs');
        var data = qs.stringify({
          'grant_type': 'password',
          'scope': 'ChallengesApi offline_access',
          'username': 'get.going@getupp.io',
          'password': 'Greenie20',
          'client_id': 'NativeAppClient',
          'client_secret': '2bbc6933-8ed6-4e15-b06d-5c679ad07bd8' 
        });
        var config = {
          method: 'post',
          url: 'https://identity-dev.getupp.io/connect/token',
          headers: { 
            'Content-Type': 'application/x-www-form-urlencoded', 
            'Cookie': 'ARRAffinity=327e34219519df25bedba7e0d77e23fac1d7c3ad5d9549029cf01e7a4117edac; ARRAffinitySameSite=327e34219519df25bedba7e0d77e23fac1d7c3ad5d9549029cf01e7a4117edac'
          },
          data : data
        };
        
        axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    return (
        <div className="loginForm">
            <div className="loginFormContent">
                <form>
                    <h1>Log ind</h1>
                    <input 
                        required
                        type="email"
                        placeholder="Email"
                    />
                    <input placeholder="Password" type="password" />
                    <label>
                        <input 
                            type="checkbox"/> Husk mig!</label>
                    <button  onClick={ myFunction} type="submit" >Log ind</button>


                    <h4 className="loginFormOpret">Har du ikke en bruger? Opret en <Link to="/signup">her</Link></h4>
                </form>
            </div>
            <div className="loginFormFadeBottom" />
        </div>
    )
}

export default LoginForm
