import React, { useEffect } from 'react';
// import "./HomeScreen.css";
import Nav from '../../components/Navbar/Nav';
import Test from '../../Test';
import Banner from '../../components/Banner/Banner';
// import Nav from "../NavCopy";
import Footer from '../../components/Footer/Footer';

function HomeScreen() {
    useEffect(() => {
        document.title = `GetUpp Live`;
      });

    return (
        <div>
            <Nav />
            
            <Banner />
            {/* <Row /> */}

            <Test />

            <Footer />

        </div>
    );
}

export default HomeScreen;
