import React, { useEffect, useState } from 'react';
import "./Footer.css";

import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faAppStore, faFacebook, faGooglePlay, faLinkedinIn} from "@fortawesome/free-brands-svg-icons";

// Pictures
// import logo from "./img/GetUpp_logo.jpg";
// import appStore from "../img/AppStore.png";
// import googlePlay from "../img/GooglePlay.png";
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

function Footer() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {

        const changeWidth = () => {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', changeWidth)

        return () => {
            window.removeEventListener('resize', changeWidth)
        }
    }, [])

    // new tab
    // const newTab = () => {
    //     window.open("http://google.dk");
    // };
    

    return (
        <div className="footer">
            {/* <div className="footLogo">
                <img className="footLogoImg" src={logo} alt=""/>
            </div> */}
                <div className="footInfo">
                    <div className="footAdress">
                        <p className="footHead"><strong>GetUpp A/S</strong></p>
                        <p>
                            Nupark 51 <br />
                            7500 Holstebro<br />
                            Denmark<br />
                            CVR: 41818069 <br /> <br />
                        <a className="footLink" href="http://www.getupp.dk" target="_blank" rel="noreferrer noopener">
                            www.GetUpp.dk
                        </a></p>
                    </div>
                    <div className="footContact">
                        <p className="footHead"><strong>Contact &amp; Support</strong></p>
                        <p className="footContactInfo">
                            <a href="tel:+4531160101"><FontAwesomeIcon icon={faPhoneAlt}></FontAwesomeIcon> +45 31 16 01 01</a><br/>
                            <a href="mailto:support@getupp.io"><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon> support@getupp.io</a></p>

                        <p className="footLink">
                            <a href="https://www.iubenda.com/privacy-policy/44229982" target="_blank" rel="noreferrer noopener">
                                Privatlivspolitik
                            </a><br/>
                            <a href="https://www.iubenda.com/privacy-policy/44229982/cookie-policy" target="_blank" rel="noreferrer noopener">
                                Vilkår og Betingelser
                            </a><br/>
                            <a href="https://www.iubenda.com/terms-and-conditions/44229982" target="_blank" rel="noreferrer noopener">
                                Cookie Politik
                            </a>
                        </p>

                    </div>
        
                    <div className="footSocial">
                    <p className="footHead"><strong>Find us</strong></p>
                    <a href="https://www.linkedin.com/company/it-s-time-to-getupp" target="_blank" rel="noreferrer noopener"><FontAwesomeIcon className="faIconFoot" icon={faLinkedinIn} ></FontAwesomeIcon></a>
                    <a href="https://www.facebook.com/GetUpp-102981038605030/" target="_blank" rel="noreferrer noopener"><FontAwesomeIcon className="faIconFoot" icon={faFacebook} ></FontAwesomeIcon></a><br />
                    {/* <a href="https://apps.apple.com/us/app/getupp-motivation/id1542569656" target="_blank" rel="noreferrer noopener"><img className="footStore" src={appStore} alt="" /></a> <br /> */}
                    <a href="https://apps.apple.com/us/app/getupp-motivation/id1542569656" target="_blank" rel="noreferrer noopener"><FontAwesomeIcon className="faIconFoot" icon={faAppStore}></FontAwesomeIcon></a>
                    {/* <a href="https://play.google.com/store/apps/details?id=io.getupp.stayactive" target="_blank" rel="noreferrer noopener"><img className="footStore" src={googlePlay} alt="" /></a> */}
                    <a href="https://apps.apple.com/us/app/getupp-motivation/id1542569656" target="_blank" rel="noreferrer noopener"><FontAwesomeIcon className="faIconFoot" icon={faGooglePlay}></FontAwesomeIcon></a> <br />
                    </div>
    
                </div>
            
        </div>
    );
}

export default Footer;
