import React, { useEffect } from 'react'

// Sider
import Footer from '../Footer/Footer';
import Nav from '../Navbar/Nav';
// import Privatlivspolitik from '../Privatlivspolitik';

function Profile() {
    useEffect(() => {
        document.title = `Profil`;
      });

    return (
        <div className="Profile">
            Profile
        </div>
    )
}

export default Profile;
