import React, { Suspense } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {createBrowserHistory} from 'history';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser, loginUser } from '../features/Reducers/loginSlice';

// Routes
import PublicRoute from '../Routes/PublicRoutes';

import InstructorScreen from './Instructor/Instructor';
import AdminScreen from './Admin/Admin';

const hist = createBrowserHistory();

function App() {
    // const user = {
  //   name: "mia",
  // }
  // const user = null;

  const user = useSelector((state) => state.login.user);
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const dispatch = useDispatch();
  let { role } = user;
  // useEffect(() => {
  //   const unsubscribe =auth.onAuthStateChanged((UserAuth) => {
  //     if (UserAuth) => 7
  //     dispatch(
  //       login({
  //         uid: UserAuth.uid,
  //         email: UserAuth.email,
  //       })
  //     );
  //   })
  // })

  return (
    // <div className="app">
      // <ProvideAuth>
      <Router history={hist}>
        <Suspense fallback="loading">
          {!(user && isLoggedIn) ? 
           role == 'Admin' ? <InstructorScreen /> : <AdminScreen />
          : 
          <PublicRoute />
          }
         </Suspense>
      </Router>
      // </ProvideAuth>
    // </div>
  );
}

export default App;
