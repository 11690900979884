import React from 'react';
import { Link } from 'react-router-dom';
import "./SignUpForm.css"

function SignUpForm() {
    return (
        <div className="signUpForm">
            <div className="signUpFormContent">
            <form>
                    <h1>Opret en bruger </h1>
                    <input 
                        type="text"
                        name="username"
                        className="formInput"
                        placeholder="Brugernavn"
                        />
                    <input 
                        type="email"
                        name="email"
                        className="formInput"
                        placeholder="Email"
                        />
                    <input 
                        type="password"
                        name="password"
                        className="formInput"
                        placeholder="Adgangskode"
                        />
                    <input 
                        type="password2"
                        name="password2"
                        className="formInput"
                        placeholder="Bekræft adgangskode"
                        />
                <button className="formInputButton" type="submit">Sign up</button>
                <h4 className="signUpFormOpret">Har du allerede en konto? Login ind <Link to="/login">her</Link></h4>
            </form>
            </div>
        </div>
    )
}

export default SignUpForm
