import React from 'react';
import { AccountCircle } from '@mui/icons-material';
import { AppBar, CssBaseline, Toolbar } from '@mui/material';
import logo from "../../img/GetUpp_logo.jpg";
import { Link } from 'react-router-dom';
import './Admin-nav.css';
import { useTranslation } from 'react-i18next';

function AdminNav() {
    const {t, i18n} = useTranslation();

    return (
        <div>
            <AppBar position="sticky" className="navbar">
                <CssBaseline />
                <Toolbar>
                    <Link to='/'>
                        <img className="navLogo" src={logo} alt=""/>
                    </Link>
                    <div className='admin-nav-options'>Admin | <button className='logout-button'>{t('Logout')}</button></div>
                    <Link to='/profile'>
                        <AccountCircle className="admin-nav-avatar" />
                    </Link>
                </Toolbar>
            </AppBar>
        </div>
    )
}

export default AdminNav;
