import React, {Suspense, useEffect, useState} from 'react';
import { Grid, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel, Card, CardContent, Button } from '@mui/material';
import {useTranslation} from "react-i18next";
import './Allvideos.css';
import imageFile from '../../img/Aandedraet.jpg'
import { PlayArrow,ArrowUpward } from '@mui/icons-material';

function AllVideos() {
    const getInstructors = () => {
        return [
            {name:'Fie Tang'},
            {name:'Trine Andersen'},
            {name:'Adal Faragalla'},
            {name:'Henrik Lipka'}
        ];
    };
    const getTeams = () => {
        return [
            {name:'Morgenbevægelse'},
            {name:'Puls & power'},
            {name:'Åndedræt'},
            {name:'Mental Breathing'},
            {name:'Yoga'}
        ];
    };
    const {t, i18n} = useTranslation();
    const [instructors, setInstructors] = useState(getInstructors);
    const [Teams, setTeams] = useState(getTeams);
    const [listItems, setListItems] = useState([]);
	const [isFetching, setIsFetching] = useState(false);
	const [page, setPage] = useState(1);
    const [visible, setVisible] = useState(false)

	useEffect(() => {
		fetchData();
		window.addEventListener('scroll', handleScroll);
	}, []);

	const handleScroll = () => {
		if (
			Math.ceil(window.innerHeight + document.documentElement.scrollTop) !== document.documentElement.offsetHeight ||
			isFetching
		)
			return;
		setIsFetching(true);
		console.log(isFetching);
	};

	const fetchData = async () => {
		setTimeout(async () => {
			const result = await fetch(`https://picsum.photos/v2/list?page=${page}`);
			const data = await result.json();
			setPage(page + 1);
			setListItems(() => {
				return [...listItems, ...data];
			});
		}, 1000);
	};

	useEffect(() => {
		if (!isFetching) return;
		fetchMoreListItems();
	}, [isFetching]);

	const fetchMoreListItems = () => {
		fetchData();
		setIsFetching(false);
	};

  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  
  window.addEventListener('scroll', toggleVisible);

    const createFilters = () => {
        return(
            <div>
                <Button color="primary" className="scroll-to-top-button" style={{display: visible ? 'inline' : 'none'}}>
                    <ArrowUpward onClick={scrollToTop} 
                    style={{display: visible ? 'inline' : 'none'}} />
                </Button>
                <FormControl component="fieldset" className='form-control'>
                    <FormLabel component="legend" className="filter-label">{t('Instructor')}</FormLabel>
                    <RadioGroup
                        aria-label="instructor"
                        defaultValue="1"
                        name="radio-buttons-group"
                    >
                        {instructors.map((instructor,index) => {
                            return <FormControlLabel key={index} labelPlacement="start" value={index} className="filter-options" control={<Radio size="small" sx={{color: '#404040','&.Mui-checked': {color: '#404040',},}}/>} label={instructor.name} />
                        })}
                    </RadioGroup>
                </FormControl>
                <FormControl component="fieldset" className='form-control'>
                    <FormLabel component="legend" className="filter-label">{t('Team')}</FormLabel>
                    <RadioGroup
                        aria-label="Team"
                        defaultValue="1"
                        name="radio-buttons-group"
                    >
                        {Teams.map((team, index) => {
                            return <FormControlLabel key={index} labelPlacement="start" value={index} className="filter-options" control={<Radio size="small" sx={{color: '#404040','&.Mui-checked': {color: '#404040',},}} />} label={team.name} />
                        })}
                    </RadioGroup>
                </FormControl>
                <FormControl component="fieldset" className='form-control'>
                <FormLabel component="legend" className="filter-label">{t('Language')}</FormLabel>
                    <RadioGroup
                        aria-label="Language"
                        defaultValue="EN"
                        name="radio-buttons-group"
                    >
                        <FormControlLabel labelPlacement="start" value="DA" className="filter-options" control={<Radio size="small" sx={{color: '#404040','&.Mui-checked': {color: '#404040',},}} />} label={`${t('Danish')}`} />
                        <FormControlLabel labelPlacement="start" value="EN" className="filter-options" control={<Radio size="small" sx={{color: '#404040','&.Mui-checked': {color: '#404040',},}} />} label={`${t('English')}`} />
                    </RadioGroup>
                </FormControl>
            </div>
        );
    };

        return (
            <div>
                <Grid container spacing={3} className="all-videos-page">
                    <Grid item xs={12} sm={4} md={3} lg={3}>
                        {createFilters()}
                    </Grid>
                    <Grid item xs={12} sm={8} md={9} lg={9}>
                        <p className='font-weight-bold video-block-title'>{t('All Videos')}</p>
                        <Grid container spacing={3}>
                            {listItems.map((listItem) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={listItem.id}>
                                    <Card className="video-card" style={{position:'relative'}}>
                                        <div className='overlay'></div>
                                        <button
                                            disabled={false}
                                            style={{
                                            display:'none'
                                            }}
                                            className="arrow-buttons play-button"
                                        >
                                            <PlayArrow />
                                        </button>
                                        {/* <PlayArrow className=""/> */}
                                        <CardContent className='video-card-content'>
                                            <Suspense fallback={<img src={imageFile} alt='Avatar' className='video-slider-media' />}>
                                                <img src={imageFile} alt='Avatar' className='video-slider-media' />
                                            </Suspense>
                                            <p className='video-scroll-menu-text font-weight-bold'>Video Title Here</p>
                                            <p className='video-scroll-menu-text'>Duration</p>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                        {isFetching && <h1>Fetching more list items...</h1>}
                    </Grid>
                </Grid>
            </div>
        );
    
};

export default AllVideos;
