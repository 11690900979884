import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// views
import Profile from '../components/Profile/Profile';
import FavoritesScreen from '../components/Favorites/Favorites';
import SkemaScreen from '../components/Skema/Skema';
import DashboardScreen from '../components/Dashboard/Dashboard';
import AllVideosScreen from '../components/Allvideos/Allvideos';
import UploadScreen from '../components/Upload/Upload';
import { useSelector } from 'react-redux';

function InstructorRoutes (){
  const user = useSelector((state) => state.login.user);
  // const isLoggedIn = useSelector((state) => state.login.isLoggedIn);

    return (
      <Switch>
        <Route path="/dashboard" component={DashboardScreen} />
        <Route path="/all-videos" component={AllVideosScreen} />
        <Route path="/favorites" component={FavoritesScreen} />
        <Route path="/skema" component={SkemaScreen} />
        <Route path="/profile" component={Profile} />
        <Route path="/upload" component={UploadScreen} />
        <Redirect to="/dashboard" />
      </Switch>
    );
}

export default InstructorRoutes;